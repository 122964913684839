import React from "react";
import { useEffect, useState } from "react";
import toplogo from "../assets/images/tlogo.png";
import redemptionImg from "../assets/images/redemption_form_img.png";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTemplateData } from "../services/apiservice";
import "react-responsive-modal/styles.css";
import "../styles/template.css";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export const RedemptionForm = () => {
  let [loading, setLoading] = useState(true);
  let [products, setProducts] = useState([]);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const [variants, setVariants] = useState([]);
  const [swagpackIds, setSwagpackIds] = useState([]);
  const [inventoryId, setInventoryId] = useState(null);

  const [showOnlyEmail, setShowOnlyEmail] = useState(false);

  const [redemptionData, setRedemptionData] = useState({
    backroundColor: "",
    logoImageUrl: "",
    buttonColor: "",
    logoImage: "",
  });
  const [address, setAddress] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emp_id: -1,
    calling_code: "" || "+91",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  let navigate = useNavigate();
  let params = { token: token };
  const { state } = useLocation();
  const { selectedPack } = state;
  const swagpacks = selectedPack?.selectedPack;
  const [errors, setErrors] = useState({});
  const [shipmentId, setShipmentId] = useState();

  useEffect(() => {
    setLoading(true);
    setErrors({});
    if (token === null || token === "" || token === undefined) {
      setLoading(false);
      navigate("/");
      return;
    }

    if (!swagpacks) {
      navigate(document.referrer);
    }

    getTemplateIdFromCoupon();
    updateUiWithSelectedPack();
    getCouponItems();
  }, []);

  function getTemplateIdFromCoupon() {
    getTemplateData()
      .then((e) => {
        setRedemptionData({
          backgroundColor:
            e.data.result.pageBackgroundColor || e.data.page_background_color,
          logoImageUrl: e.data.result.logoImage || e.data.result.logoImage,
          buttonColor: e.data.result.buttonColor || e.data.result.buttonColor,
          logoImage: e.data.result.logoImage,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function updateUiWithSelectedPack() {
    swagpacks ? setInventoryId(swagpacks[0].inventory_id) : setInventoryId(-1);
    if (swagpacks && swagpacks.length > 0) {
      let packIds = swagpacks.map((swagpack) => swagpack.id);
      setSwagpackIds(packIds);
    } else {
      setSwagpackIds(-1);
    }

    if (swagpacks && swagpacks.length > 0) {
      let products = swagpacks
        .map((swagpack) => swagpack.products.map((product) => product))
        .flat();
      setProducts(products);
    } else {
      setProducts([]);
    }

    const extractedVariants = products?.map((product) => product.variants);
    setFilteredVariants(extractedVariants);
  }

  function handleSubmit(e) {
    e.preventDefault();

    const variantsArray = products.map((product, index) => {
      const productVariants = product.variants;
      const selectedSize =
        variants[`${product.product_id}_${productVariants[0].id}`];
      if (productVariants.length === 1) {
        const singleSize = productVariants[0].size;
        return {
          product_id: product.product_id,
          sku: singleSize,
          quantity: product.quantity,
          pack_id: product.swagpack_id,
        };
      } else {
        return {
          product_id: product.product_id,
          sku: selectedSize || "",
          quantity: product.quantity,
          pack_id: product.swagpack_id,
        };
      }
    });

    const formData = {
      ...address,
      variants: variantsArray,
      swagpack_ids: swagpackIds,
      inventoryId: inventoryId,
      token,
    };

    if (validateForm(formData)) {
      postData(formData);
    }
  }

  const handleSizeChange = (productId, variantId, selectedSize) => {
    setVariants((prevSizes) => {
      const updatedSizes = {
        ...prevSizes,
        [`${productId}_${variantId}`]: selectedSize,
      };
      return updatedSizes;
    });
  };

  const handleClearAll = () => {
    setAddress({
      first_name: "",
      last_name: "",
      email: "",
      emp_id: -1,
      calling_code: "" || "+91",
      phone: "",
      address1: "",
      address2: "",
      address3: "",
      landmark: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });
    setErrors({});
    setVariants({});
  };

  async function postData(formData) {
    if (token === "guidewirec0c51933a0bc49228a219d3836070246") {
      address.country = "India";
    }

    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_URL}/v4/customer/redemption/shipmentRequest?token=${token}`,
        formData
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setModalShown(true);
          setShipmentId(res.data.result.shipmentId);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTimeout(() => {
          toast.warning(
            err.response.data.message ||
              "Something went wrong, please try again later!",
            {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            },
            1000
          );
        });
        navigate({
          pathname: "/",
          search: `?${createSearchParams(params)}`,
        });
      });
  }

  const validateForm = (data) => {
    const validationErrors = {};

    if (showOnlyEmail) {
      if (!data.email.trim()) {
        validationErrors.email = "Email is required";
        setErrors(validationErrors);
        return false;
      } else if (!/\S+@\S+\.\S+/.test(data.email)) {
        validationErrors.email = "Email is invalid";
        setErrors(validationErrors);
        return false;
      }
      return true;
    } else {
      if (!data.first_name.trim()) {
        validationErrors.first_name = "First name is required";
        setErrors(validationErrors);
        return false;
      } else if (data.first_name.length < 4) {
        validationErrors.first_name =
          "First name must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      if (!data.email.trim()) {
        validationErrors.email = "Email is required";
        setErrors(validationErrors);
        return false;
      } else if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          data.email
        ) === false
      ) {
        validationErrors.email = "Email is invalid";
        setErrors(validationErrors);
        return false;
      }

      if (!data.phone.trim()) {
        validationErrors.phone = "Phone no is required";
        setErrors(validationErrors);
        return false;
      } else if (data.phone.length >= 7) {
        if (/^[+,0-9][0-9]{6,15}$/.test(data.phone) === false) {
          validationErrors.phone = "Phone no is invalid";
          setErrors(validationErrors);
          return false;
        }
      } else {
        validationErrors.phone = "Phone no is invalid";
        setErrors(validationErrors);
        return false;
      }

      if (!data.country.trim()) {
        validationErrors.country = "Country is required";
        setErrors(validationErrors);
        return false;
      } else if (data.country.length < 4) {
        validationErrors.country = "Country must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      if (!data.state.trim()) {
        validationErrors.state = "State is required";
        setErrors(validationErrors);
        return false;
      } else if (data.state.length < 3) {
        validationErrors.state = "State must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      if (!data.city.trim()) {
        validationErrors.city = "City is required";
        setErrors(validationErrors);
        return false;
      } else if (data.city.length < 3) {
        validationErrors.city = "City must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      if (!data.pincode.trim()) {
        validationErrors.pincode = "Pincode is required";
        setErrors(validationErrors);
        return false;
      } else if (data.pincode.length > 3) {
        if (/^[a-zA-Z0-9\- ]{4,10}$/.test(data.pincode) === false) {
          validationErrors.pincode = "Pincode is invalid";
          setErrors(validationErrors);
          return false;
        }
      } else {
        validationErrors.pincode = "Pincode is invalid";
        setErrors(validationErrors);
        return false;
      }

      if (!data.address1.trim()) {
        validationErrors.address1 = "Shipping address is required";
        setErrors(validationErrors);
        return false;
      } else if (data.address1.length < 4) {
        validationErrors.address1 =
          "Shipping address must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      if (!data.landmark.trim()) {
        validationErrors.landmark = "Landmark is required";
        setErrors(validationErrors);
        return false;
      } else if (data.landmark.length < 4) {
        validationErrors.landmark =
          "Landmark must be at least 4 characters long";
        setErrors(validationErrors);
        return false;
      }

      return true;
    }
  };

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setAddress({
      ...address,
      [name]: value,
    });

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  }

  function Modal({ children, shown, close }) {
    return shown ? (
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
        }}
        className="modal-backdrop"
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      >
        <div
          style={{
            width: "auto",
            height: "auto",
            maxHeight: "500px",
            maxWidth: "450px",
            backgroundColor: "white",
            padding: "25px",
            fontSize: "12px",
            borderRadius: "15px",
          }}
          className="modal-content"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {children}

          <button
            style={{
              marginTop: "20px",
              padding: "8px",
              border: "none",
              fontSize: "17px",
              fontWeight: "600",
              backgroundColor: "#d7458f",
              color: "white",
              borderRadius: "8px",
            }}
            onClick={close}
          >
            Okay
          </button>
        </div>
      </div>
    ) : null;
  }

  function getCouponItems() {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_URL}/v4/customer/redemption/couponItems?token=${token}`
      )
      .then((res) => {
        if (res.data.success && res.data.result) {
          if (
            res.data.result.swagpacks &&
            res.data.result.swagpacks.length > 0
          ) {
            setShowOnlyEmail(res.data.result.email_only);
            setLoading(false);
          } else {
            setTimeout(() => {
              setLoading(false);
              toast.warning("Swagpacks not found", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }, 100);
            navigate({
              pathname: "/",
              search: `?${createSearchParams(params)}`,
            });
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          toast.warning(
            err.response.data.message ||
              "Something went wrong, please try again later",
            {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }, 100);
        navigate({
          pathname: "/",
          search: `?${createSearchParams(params)}`,
        });
      });
  }

  return (
    <div className="bg-white">
      {loading ? (
        <div className="loading-indicator-admin"> </div>
      ) : (
        <div>
          <div className="row ">
            <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
              <div className="top-margin-two header px-3 py-3">
                <div className="">
                  <img
                    className="img-fluid theme-logo-image"
                    src={redemptionData.logoImage || toplogo}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-3 d-flex mb-5">
            <div className="col-xl-6 margin">
              <img
                src={redemptionImg}
                alt=""
                className="img-fluid"
                style={{ height: "100%", width: "-webkit-fill-available" }}
              />
            </div>
            <div className=" col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12 pt-0 p-5 margin">
              <div>
                <p
                  className="text-start m-0 mt-4"
                  style={{ fontWeight: "800", paddingLeft: "12px" }}
                >
                  Please fill out the following details and click 'Submit'
                </p>
              </div>
              {!showOnlyEmail && (
                <>
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div
                      className="col bg-white"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div className="row ">
                        <div className="col-xl-12 col-sm-12  rounded">
                          <label
                            style={{
                              fontWeight: "800",
                            }}
                            className="form-label pt-3"
                          >
                            First Name<span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            onChange={handleChange}
                            style={{
                              borderColor: errors.first_name ? "red" : "",
                            }}
                            value={address.first_name}
                          />
                        </div>
                        <div className="col-xl-12 col-sm-12 pt-2  rounded">
                          <label
                            style={{ fontWeight: "800" }}
                            className="form-label pt-3"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="last_name"
                            onChange={handleChange}
                            value={address.last_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 pt-2">
                        {token ===
                        "guidewirec0c51933a0bc49228a219d3836070246" ? (
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefaultUsername"
                            className="form-label pt-3"
                          >
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefaultUsername"
                            className="form-label pt-3"
                          >
                            Work Email<span style={{ color: "red" }}>*</span>
                          </label>
                        )}

                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="inputGroupPrepend2"
                            id="validationDefaultUsername"
                            placeholder="Work email"
                            onChange={handleChange}
                            name="email"
                            style={{
                              borderColor: errors.email ? "red" : "",
                            }}
                            value={address.email}
                          />
                        </div>
                      </div>

                      {token === "guidewirec0c51933a0bc49228a219d3836070246" ? (
                        <div className="col-md-12 pt-2">
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefault03"
                            className="form-label pt-3"
                          >
                            Employee Id<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationDefault03"
                            onChange={handleChange}
                            name="emp_id"
                            value={address.emp_id}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12 pt-2">
                        <label
                          style={{ fontWeight: "800" }}
                          htmlFor="validationDefault03"
                          className="form-label pt-3"
                        >
                          Phone Number
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="validationDefault03"
                          placeholder="Phone number"
                          onChange={handleChange}
                          name="phone"
                          style={{
                            borderColor: errors.phone ? "red" : "",
                          }}
                          value={address.phone}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-2 styled-select">
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefault03"
                            className="form-label pt-3"
                          >
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            onChange={handleChange}
                            name="country"
                            style={{
                              borderColor: errors.country ? "red" : "",
                            }}
                            value={address.country}
                          />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 pt-2">
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefault03"
                            className="form-label pt-3"
                          >
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            name="state"
                            style={{
                              borderColor: errors.state ? "red" : "",
                            }}
                            value={address.state}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-2">
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefault03"
                            className="form-label pt-3"
                          >
                            {" "}
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationDefault03"
                            onChange={handleChange}
                            name="city"
                            placeholder="City"
                            style={{
                              borderColor: errors.city ? "red" : "",
                            }}
                            value={address.city}
                          />
                        </div>
                        <div className="col pt-2">
                          <label
                            style={{ fontWeight: "800" }}
                            className="form-label pt-3"
                            htmlFor="validationDefault03"
                          >
                            Pin code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="EX: 110927"
                            onChange={handleChange}
                            onKeyDown={(evt) =>
                              (evt.key === "e" || evt.key === ".") &&
                              evt.preventDefault()
                            }
                            name="pincode"
                            style={{
                              borderColor: errors.pincode ? "red" : "",
                            }}
                            value={address.pincode}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 pt-2">
                        <label
                          style={{ fontWeight: "800" }}
                          htmlFor="validationDefault03"
                          className="form-label pt-3"
                        >
                          Shipping Address Line
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationDefault03"
                          placeholder="Shipping Address"
                          onChange={handleChange}
                          name="address1"
                          style={{
                            borderColor: errors.address1 ? "red" : "",
                          }}
                          value={address.address1}
                        />
                      </div>

                      <div className="col-md-12 pt-2">
                        <label
                          style={{ fontWeight: "800" }}
                          htmlFor="validationDefault03"
                          className="form-label pt-3"
                        >
                          Landmark<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationDefault03"
                          placeholder="Nearby Landmark"
                          onChange={handleChange}
                          name="landmark"
                          style={{
                            borderColor: errors.landmark ? "red" : "",
                          }}
                          value={address.landmark}
                        />
                      </div>
                      <div className="row pt-2"></div>

                      <div>
                        {products?.map(
                          (product, index) =>
                            product?.variants.length > 1 && (
                              <div
                                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-2"
                                key={index}
                              >
                                <div className="col text-center cardh">
                                  <div>
                                    <div className="cardt">
                                      <div className="cpt-3">
                                        {product.variants.length > 1 && (
                                          <h6
                                            className="d-flex justify-content-start pt-3"
                                            style={{ fontWeight: "800" }}
                                          >
                                            {`Please mention the size for ${product.product_name}`}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </h6>
                                        )}
                                        {product.variants.length > 1 ? (
                                          <div>
                                            <select
                                              required
                                              id={`sizeDropdown${index}`}
                                              className="form-select"
                                              style={{ width: "100%" }}
                                              aria-label="Select Size"
                                              onChange={(e) =>
                                                handleSizeChange(
                                                  product.product_id,
                                                  product.variants[0].id,
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Size
                                              </option>
                                              {product.variants.map(
                                                (size, sizeIndex) => (
                                                  <option
                                                    key={size.id}
                                                    value={size.size}
                                                  >
                                                    {size.size}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      {Object.keys(errors).length > 0 && (
                        <div className="row pt-3">
                          <span style={{ color: "red", fontSize: "16px" }}>
                            {errors[Object.keys(errors)[0]]}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#d7458f",
                            color: "#fff",
                          }}
                          className="btn mb-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #3F3D56",
                            color: "#3F3D56",
                          }}
                          className="btn mb-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                          onClick={handleClearAll}
                        >
                          Clear all
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
              {showOnlyEmail ? (
                <>
                  <form className="row g-3">
                    <div
                      className="col bg-white"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div className="col-md-12 pt-2">
                        {token ===
                        "guidewirec0c51933a0bc49228a219d3836070246" ? (
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefaultUsername"
                            className="form-label pt-3"
                          >
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          <label
                            style={{ fontWeight: "800" }}
                            htmlFor="validationDefaultUsername"
                            className="form-label pt-3"
                          >
                            Work Email<span style={{ color: "red" }}>*</span>
                          </label>
                        )}
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationDefaultUsername"
                            aria-describedby="inputGroupPrepend2"
                            placeholder="Work email"
                            onChange={handleChange}
                            name="email"
                            style={{
                              borderColor: errors.email ? "red" : "",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row pt-2"></div>
                      <div>
                        {products.map((product, index) => (
                          <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-2"
                            key={index}
                          >
                            <div className="col text-center cardh">
                              <div>
                                <div className="cardt">
                                  <div className="cpt-3">
                                    {product.variants.length > 1 && (
                                      <h6
                                        className="d-flex justify-content-start pt-3"
                                        style={{ fontWeight: "800" }}
                                      >
                                        {`Please mention the size for ${product.product_name}`}
                                        <span style={{ color: "red" }}>*</span>
                                      </h6>
                                    )}
                                    {product.variants.length > 1 ? (
                                      <div>
                                        <select
                                          required
                                          id={`sizeDropdown${index}`}
                                          className="form-select"
                                          style={{ width: "100%" }}
                                          aria-label="Select Size"
                                          onChange={(e) =>
                                            handleSizeChange(
                                              product.product_id,
                                              product.variants[0].id,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select Size</option>
                                          {product.variants.map(
                                            (size, sizeIndex) => (
                                              <option key={size.id}>
                                                {size.size}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#d7458f",
                          color: "#fff",
                        }}
                        className="btn  mt-2  mb-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <button
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #3F3D56",
                          color: "#3F3D56",
                        }}
                        className="btn  mt-2  mb-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        onClick={handleClearAll}
                      >
                        Clear all
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <Modal
                shown={modalShown}
                close={() => {
                  setModalShown(false);
                  navigate(`/redemption/feedback?token=${params.token}`, {
                    state: {
                      shipmentId,
                      inventoryId,
                      email: address.email,
                    },
                  });
                }}
                center
              >
                <div className="text-center">
                  <FontAwesomeIcon
                    style={{ color: "#52E6CA", fontSize: "40px" }}
                    icon={faCircleCheck}
                  />
                  <h5 className="pt-3">
                    Your form has been submitted successfully!
                  </h5>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};
