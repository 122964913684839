import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { toast, ToastContainer } from "react-toastify";

export const PersonalizedVideo = () => {
    const [loading, setLoading] = useState(true);
    const [codeDetails, setCodeDetails] = useState({});
    const [isValidUrl, setIsValidUrl] = useState(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    let params = { token: token };
    const navigate = useNavigate();

    useEffect(() => {
        if (token === null || token === '' || token === undefined) {
            setLoading(false)
            navigate("/");
            return
        }

        if (!codeDetails.first_name) {
            getCodeDetails();
        } else {
            verifyYoutubeUrl(codeDetails.personalized_video_url)
        }

    }, [token, codeDetails])

    const getCodeDetails = async () => {
        axios.get(`${process.env.REACT_APP_URL}/customer/gift-of-choice/detail/${token}`).then((res) => {
            if (res.data.success) {
                setCodeDetails(res.data.result);
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false)
            setTimeout(() => {
                toast.warning(err.message || "Something went wrong, please try again later", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }, 100)
        })
    }

    const verifyYoutubeUrl = (personalizedVideoUrl) => {
        if (isValidYoutubeUrl(personalizedVideoUrl)) {
            setIsValidUrl(true)
        } else {
            setIsValidUrl(false);
        }
    }


    const isValidYoutubeUrl = (url) => {
        let regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        return regex.test(url);
    }

    return (
        <>
            {loading ? (
                <div className="loading-indicator-admin" ></div>
            ) : (
                <>
                    <ToastContainer />
                    <div className='d-flex justify-content-center p-5'>
                        <p style={{ fontSize: '25px' }}>Here is a video message for you by {codeDetails.first_name + ' ' + codeDetails.last_name}</p>
                    </div>
                    {isValidUrl ? <div> <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <iframe
                            title="YouTube video player"
                            style={{ width: '100vh', display: 'flex', alignItems: 'center' }}
                            height="100%"
                            src={codeDetails.personalized_video_url}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            autoplay
                        ></iframe>
                    </div> <div className='text-center pt-4' style={{ fontSize: '1.3rem', textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}> <p onClick={() => {
                        navigate(`/redemption/products?token=${params.token}`)
                    }}>Open your gift</p></div></div> : <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                        <a href={codeDetails.personalized_video_url} target='_blank' style={{ background: '#d7458f', color: 'white', padding: '0.7rem', fontWeight: '600', borderRadius: '11px', textDecoration: 'none' }}>See the video</a>

                        <p href={codeDetails.personalized_video_url} target='_blank' style={{ background: '#d7458f', color: 'white', padding: '0.7rem', fontWeight: '600', borderRadius: '11px', textDecoration: 'none', marginBottom: 0, cursor: 'pointer' }} onClick={() => {
                            navigate(`/redemption/products?token=${params.token}`)
                        }}>Open your gift</p>
                    </div>}
                </>
            )
            }
        </>
    )
}