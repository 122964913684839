import React from 'react'
import '../styles/footer.css'
import instaImg from '../assets/images/insta.svg'
import fbImg from '../assets/images/fb.svg'
import twitterImg from '../assets/images/twitter.svg'
import linkedin from '../assets/images/linkedin.svg'

const Footer = () => {
  return (
    <div class="footer-div p-0">
      <div className='text-white text-center mt-5'>
        <div className='sub-footer p-4 d-flex justify-content-center'>
          <p className='p-0 m-0'>🎉 Enjoy your Swagpack.</p>
        </div>
      </div>
      <div className='footer-link pt-5'>
        <div className='d-flex justify-content-center gap-2 align-items-center'>
          <a href='https://www.instagram.com/swageazyhq/' target='_blank'>
            <img src={instaImg} style={{ cursor: 'pointer' }} />
          </a>
          <a href='https://www.facebook.com/swageazyhq' target='_blank'>
          <img src={fbImg} style={{ cursor: 'pointer' }} />
          </a>
          <a href='https://www.linkedin.com/company/swageazy/' target='_blank'>
            <img src={linkedin} style={{ cursor: 'pointer' }} />
          </a>
          <a href='https://twitter.com/swageazyhq' target='_blank'>
            <img src={twitterImg} style={{ cursor: 'pointer' }} />
          </a>
        </div>
        <div className='border-bottom'></div>

        <a
          href='https://swageazy.com/'
          target={'_blank'}
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <p className='heading-5 mt-5 mb-4 text-center' style={{background: "#1E1665"}}>
            © 2024, Powered by Swageazy
          </p>
        </a>
      </div>
    </div>
  )
}

export default Footer
