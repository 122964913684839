import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ImageGallery from "react-image-gallery";
import { ToastContainer } from "react-toastify";
import "../styles/product-list.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ScratchCard from "scratch-win-react";
import { createSearchParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import partyPopperSvg from "../assets/images/gift-of-choice-images/Group 8.svg";
import ConfettiExplosion from "confetti-explosion-react";
import main from "../assets/images/GOC Scartch Card new (1).png";
import smallScreenMain from "../assets/images/iPhone 14 & 15 Pro Max - 1.png";
import mediumScreenMain from "../assets/images/iPad mini 8.3 - 1.png";
import largeScreenMain from "../assets/images/MacBook Air - 1.png";

document.body.style.backgroundColor = "rgb(247,246,255)";

export const ProductListPage = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [prodList, setProdList] = useState([]);
  const [countryId, setCountryId] = useState(1);
  const [modalProd, setModalProd] = useState({});

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  let params = { token: token };
  const [productImages, setProductImages] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showScratch, setShowScratch] = useState(true);
  const [isExploding, setIsExploding] = React.useState(false);
  const [errorFound, setErrorFound] = useState(false);
  const [scratchImage, setScratchImage] = useState(main);
  const [imageToZoom, setImageToZoom] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cartId, setCartId] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      setLoading(false);
      navigate("/");
      return;
    }

    setLoading(true);
    getCouponItems();

    const width = window.innerWidth;
    if (width < 767) {
      setScratchImage(smallScreenMain);
    } else if (width >= 767 && width < 1024) {
      setScratchImage(mediumScreenMain);
    } else if (width >= 1024 && width < 1440) {
      setScratchImage(largeScreenMain);
    } else {
      setScratchImage(main);
    }
  }, [token]);

  useEffect(() => {}, [productImages, modalProd]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isExploding) {
      const timeout = setTimeout(() => {
        setIsExploding(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isExploding]);

  const modalImages = (product) => {
    setProductImages([]);
    setSelectedProduct(product);
    setModalProd(product);

    let newProDesc = [];

    if (product?.images !== undefined) {
      product?.images?.forEach((image) => {
        newProDesc.push({
          original: image.image_url || partyPopperSvg,
          thumbnail: image.image_url || partyPopperSvg,
        });
      });
    } else {
      newProDesc.push({ original: partyPopperSvg, thumbnail: partyPopperSvg });
    }
    setProductImages(newProDesc);
    setCurrentIndex(0);
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProductImages([]);
    setModalProd({});
  };

  async function getCouponItems() {
    axios
      .get(
        `${process.env.REACT_APP_URL}/customer/gift-of-choice/products?token=${token}`
      )
      .then((res) => {
        if (res.data.success) {
          setCountryId(res.data.result.country_id);
          if (countryId === 2 || res.data.result.country_id === 2) {
            if (
              res.data?.result?.rye_cart_created &&
              !res.data?.result?.rye_cart_id
            ) {
              fetchRyeCartDetails(res.data?.result?.cart_id);
            } else {
              createRyeCart(res.data?.result?.products);
            }
          } else {
            setProdList(res.data.result);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setErrorFound(true);
        setLoading(false);
      });
  }

  async function createRyeCart(products) {
    axios
      .post(
        `${process.env.REACT_APP_URL}/customer/gift-of-choice/create-cart?token=${token}`,
        { cartItems: products }
      )
      .then(async (res) => {
        if (res.data.success) {
          setCartId(res.data?.result?.cart?.id);
          await fetchRyeCartDetails(cartId || res.data?.result?.cart?.id);
        }
      })
      .catch((err) => {
        setErrorFound(true);
        setLoading(false);
      });
  }

  async function fetchRyeCartDetails(cartId) {
    axios
      .get(
        `${process.env.REACT_APP_URL}/customer/gift-of-choice/cart-details/${cartId}/${token}`
      )
      .then((res) => {
        if (res.data.success) {
          setCartId(res.data?.result?.cart_id);
          setProdList(res.data.result);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorFound(true);
        setLoading(false);
      });
  }

  const toRedeemPage = () => {
    if (countryId === 2) {
      navigate(
        `/redemption/redeem-gift/other-country?${createSearchParams(params)}`,
        {
          state: {
            product: selectedProduct,
            campaignId: prodList.campaign_id,
            cartId,
          },
        }
      );
    } else {
      navigate(`/redemption/redeem-gift?${createSearchParams(params)}`, {
        state: {
          product: selectedProduct,
          campaignId: prodList.campaign_id,
        },
      });
    }
  };

  const handleZoomClick = () => {
    setImageToZoom(productImages[currentIndex].original);
  };

  const handleZoomClose = () => {
    setImageToZoom(null);
  };

  const handleSlide = (currentIndex) => {
    setCurrentIndex(currentIndex);
  };

  return (
    <>
      {loading ? (
        <div className="loading-indicator-admin"></div>
      ) : (
        <>
          <ToastContainer />
          <div style={{ display: errorFound ? "none" : "" }}>
            <div className="col-12">
              {isExploding && <ConfettiExplosion width={4000} />}
            </div>

            {showScratch && (
              <div
                className="container-fluid d-flex justify-content-center align-items-center p-0 m-0"
                style={{ overflowY: "hidden", overflowX: "hidden" }}
              >
                <ScratchCard
                  ref={ref}
                  width={dimensions.width > 1300 ? 1300 : dimensions.width}
                  height={dimensions.height}
                  brushSize={20}
                  finishPercent={20}
                  onComplete={() => {
                    setShowScratch(false);
                    setIsExploding(true);
                  }}
                  image={scratchImage}
                >
                  <div className="screen justify-content-center">
                    <div className=" justify-content-center">
                      <div className="col-12 text-center">
                        <h2> Select Your Gift of Choice </h2>
                        {/* {prodList && prodList.email_message && (
                                                        <h4 className="mt-5"> {prodList.email_message} </h4>
                                                    )} */}
                      </div>
                      <div className="grid-container mx-auto mt-5 p-3 ">
                        {prodList &&
                          prodList.products?.map((product, index) => (
                            <div
                              key={index}
                              onClick={() => modalImages(product)}
                              className="single-product"
                            >
                              <div className="image-container">
                                <img
                                  onError={(e) =>
                                    (e.target.src = partyPopperSvg)
                                  }
                                  src={
                                    product?.images !== undefined
                                      ? product?.images[0]?.image_url
                                      : "error"
                                  }
                                  alt={product.name}
                                  className="product-image"
                                />
                              </div>
                              <div className="product-details">
                                <div
                                  className="product-name"
                                  title={product?.name}
                                >
                                  {product?.name}
                                  {product.is_recommended && (
                                    <>
                                      <br />
                                      <span className="sender-choice">
                                        Sender’s Choice
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </ScratchCard>
              </div>
            )}

            {!showScratch && (
              <>
                <div
                  className="container-fluid d-xl-flex d-lg-flex d-md-flex d-sm-block justify-content-center align-items-center p-0 m-0"
                  style={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                  <div className="screen justify-content-center">
                    <div className=" justify-content-center">
                      <div className="col-12 text-center">
                        <h2>Select Your Gift of Choice</h2>
                      </div>
                      <div className="grid-container mx-auto mt-5 p-3 ">
                        {prodList &&
                          prodList.products?.map((product, index) => (
                            <div
                              key={index}
                              onClick={() => modalImages(product)}
                              className="single-product"
                            >
                              <div className="image-container">
                                <img
                                  onError={(e) =>
                                    (e.target.src = partyPopperSvg)
                                  }
                                  src={
                                    product?.images !== undefined
                                      ? product?.images[0]?.image_url
                                      : "error"
                                  }
                                  alt={product.name}
                                  className="product-image"
                                />
                              </div>
                              <div className="product-details">
                                <div
                                  className="product-name"
                                  title={product?.name}
                                >
                                  {product?.name
                                    .split(" ")
                                    .slice(0, 5)
                                    .join(" ")}
                                  {product.is_recommended && (
                                    <>
                                      <br />
                                      <span className="sender-choice">
                                        Sender’s Choice
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div>
                      {open && (
                        <Modal
                          open={open}
                          onClose={handleClose}
                          center
                          classNames={{
                            modal: "customProductDescModal",
                          }}
                          animationDuration={400}
                        >
                          <div className="modal-card">
                            <div className="modal-img-container position-relative">
                              <div className="carousel-container">
                                <ImageGallery
                                  items={productImages}
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  disableThumbnailScroll={false}
                                  onErrorImageURL={partyPopperSvg}
                                  thumbnailHeight={100}
                                  slideOnThumbnailOver={true}
                                  thumbnailPosition={"right"}
                                  showNav={false}
                                  onSlide={handleSlide}
                                />
                              </div>
                              <div className="zoom-icon-container">
                                <i
                                  class="bi bi-zoom-in"
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: "10px",
                                    top: "85%",
                                    color: "red",
                                  }}
                                  onClick={handleZoomClick}
                                ></i>
                              </div>
                            </div>
                            <div className="product-content text-start">
                              <div
                                className="product-heading"
                                title={modalProd?.name || ""}
                              >
                                <span
                                  style={{
                                    color: "rgba(247, 24, 115, 1)",
                                    display: modalProd.is_recommended
                                      ? "block"
                                      : "none",
                                    fontSize: "14px",
                                  }}
                                >
                                  {"Sender’s Choice"}
                                </span>
                                {modalProd &&
                                modalProd.name &&
                                modalProd.name.length > 30
                                  ? `${modalProd.name.slice(0, 30)}...`
                                  : modalProd?.name || ""}
                              </div>
                              <div
                                className="product-description"
                                style={{
                                  maxHeight: "350px",
                                  minHeight: "120px",
                                  overflowY: "scroll",
                                }}
                              >
                                <div className="description-text">
                                  {modalProd && modalProd.description}
                                </div>
                                {modalProd && modalProd.key_features && (
                                  <div>
                                    <h2
                                      style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "500",
                                        color: "rgba(51, 51, 51, 1)",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      Key Features
                                    </h2>
                                    <ul className="list-box">
                                      {modalProd.key_features
                                        .split("~")
                                        .map((feature, index) => (
                                          <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              <div className="button-container justify-content-between d-flex">
                                <button
                                  className="back-btn btn"
                                  onClick={handleClose}
                                >
                                  Back
                                </button>
                                <button
                                  onClick={toRedeemPage}
                                  className="choose-gift-btn btn"
                                >
                                  Confirm Gift
                                </button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                      {imageToZoom && (
                        <Modal
                          open={!!imageToZoom}
                          onClose={handleZoomClose}
                          center
                          classNames={{
                            modal: "customZoomModal",
                          }}
                          animationDuration={400}
                        >
                          <div className="zoomed-image-container">
                            <img
                              src={imageToZoom}
                              alt="Zoomed"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </div>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {errorFound && (
            <div
              className="d-flex justify-content-center  align-items-center text-center min-vh-100"
              style={{ display: errorFound ? "block" : "none" }}
            >
              <div className="">
                <h4
                  style={{ fontSize: "30px", fontWeight: "600" }}
                  className=""
                >
                  {" "}
                  Invalid Link{" "}
                </h4>
                <br />
                <span style={{ fontSize: "24px", fontWeight: "500" }}>
                  This gift has already been redeemed or Expired
                </span>
                <br />
                <br />
                <span
                  style={{ fontSize: "20px", fontWeight: "500" }}
                  className="pt-5"
                >
                  You may close this window now
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
<script src="js/bootstrap.bundle.min.js"></script>;
