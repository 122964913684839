import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "../styles/redeem-gift.css";
import "react-responsive-modal/styles.css";
import partyPopperSvg from "../assets/images/gift-of-choice-images/Group 8.svg";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import sizeChart from "../assets/images/gift-of-choice-images/size-chart.svg";

export const OtherCountryRedeemGiftPage = () => {
  const location = useLocation();
  const product = location.state.product;
  const campaignId = location.state.campaignId;
  const cartId = location.state.cartId;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [back, setBack] = useState(false);
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const [address, setAddress] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address_line1: "",
    address_line2: "",
    city: "",
    provinceCode: "",
    country: "",
    pincode: "",
    size: "",
  });
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [feedback, setFeedback] = useState({
    shipment_id: 0,
    message: "",
  });
  const [sizeCharImage, setSizeCharImage] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  let params = { token: token };

  function handleChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    setAddress({
      ...address,
      [name]: value,
    });
  }

  const handlePhoneNumberChange = (value, country) => {
    setPhoneNumber(value);
    setSelectedCountry(country ? country.countryCode : "in");
    setAddress({
      ...address,
      phone: value,
    });
  };

  useEffect(() => {
    if (back) {
      navigate(`/redemption/products?${createSearchParams(params)}`);
    }

    let image = product?.images.find((image) => image.is_size_chart === 1);
    if (image) {
      setSizeCharImage(image.image_url);
    }
  }, [back]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    if (phoneNumber.length < 6) {
      setLoading(false);
      toast.warning("Phone number is invalid", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.first_name) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.last_name) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.address_line1) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.address_line2) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.city) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.provinceCode) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    if (!address.pincode) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!address.email) {
      setLoading(false);
      toast.warning("Please fill all details", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (product.has_size === 1) {
      if (address.size === "-1" || address.size === "") {
        setLoading(false);
        toast.warning("Please fill all details", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }

    if (form.checkValidity()) {
      axios
        .post(
          `${process.env.REACT_APP_URL}/customer/gift-of-choice/submit/cart/${cartId}/${token}`,
          { product: product, campaign_id: campaignId, address }
        )
        .then((res) => {
          if (res.data.success) {
            setFeedback({
              ...feedback,
              shipment_id: res.data.result.shipment_id,
            });
            setLoading(false);
            onOpenModal();
          }
        })
        .catch((err) => {
          setLoading(false);
          setTimeout(() => {
            toast.warning(
              err.response.data.message ||
                "Something went wrong, please try again later!",
              {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              },
              1000
            );
          });
        });
    } else {
      console.log("Form is invalid");
    }
  };

  const navigateProductList = () => {
    setBack(true);
  };

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
    window.location.href = "https://swageazy.com/";
  };

  const onCloseModal2 = () => {
    setShowSizeChart(false);
    // window.location.href = 'https://swageazy.com/'
  };

  const onCloseModalSubmit = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_URL}/customer/gift-of-choice/saveFeedback`,
        { feedback: feedback }
      )
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          window.location.href = "https://swageazy.com/";
          // setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTimeout(() => {
          toast.warning(
            err.response.data.message ||
              "Something went wrong, please try again later!",
            {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            },
            1000
          );
        });
      });
  };

  const openSizeChart = () => {
    setShowSizeChart(true);
  };

  return (
    <>
      {loading ? (
        <div className="loading-indicator-admin"></div>
      ) : (
        <div>
          <ToastContainer />
          <div className="page text-center d-flex">
            <div className="leftProductPicture d-flex flex-column">
              <div className="lightHeading">Your Gift of Choice</div>
              <div className="productName">{product.name}</div>
              <div className="img-container">
                <img
                  src={product.images[0].image_url}
                  style={{ maxHeight: "450px" }}
                  alt={product.name}
                  srcSet=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="rightAddressForm">
              <div className="addressFormheading mb-3">
                <div className="heading-question">
                  Where do you want this gift?
                </div>
                <div className="guideline">
                  Please ensure your selection and address are correct before
                  submitting.
                </div>
              </div>
              <form
                id="addressForm"
                className="addressForm row col-12"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="firstLastName col-12 d-flex justify-content-between">
                  <div className="firstNameForm text-start col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="firstName">First Name </label>
                    <input
                      autoFocus
                      type="text"
                      id="firstName"
                      name="first_name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="lastNameForm text-start col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="lastName">Last Name </label>
                    <input
                      type="text"
                      id="lastName"
                      name="last_name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                {product && product.has_size === 1 && (
                  <>
                    <div
                      className="size col-12 d-flex justify-content-between"
                      style={{ paddingRight: "40px" }}
                    >
                      <div className="size text-start form-group col-12 mx-3 my-3 d-flex flex-column">
                        <label htmlFor="size">Select size</label>
                        <select name="size" id="size" onChange={handleChange}>
                          <option value="-1"></option>
                          <option value="xs">XS</option>
                          <option value="s">S</option>
                          <option value="m">M</option>
                          <option value="l">L</option>
                          <option value="xl">XL</option>
                          <option value="xxl">XXL</option>
                          <option value="xxxl">XXXL</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="col-12 d-flex pb-3"
                      style={{ paddingLeft: "25px", cursor: "pointer" }}
                      onClick={openSizeChart}
                    >
                      <img
                        src={sizeChart}
                        alt="Swageazy Size Chart Icon"
                        style={{ maxWidth: "20px" }}
                      />
                      <span style={{ paddingLeft: "4px" }}> Size Chart </span>
                    </div>
                  </>
                )}

                <div className="address col-12 d-flex justify-content-between">
                  <div className="address1Form text-start col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="address1">Address line 1 </label>
                    <input
                      type="text"
                      id="address1"
                      name="address_line1"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="address2Form text-start col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="address2">Address line 2 </label>
                    <input
                      type="text"
                      id="address2"
                      name="address_line2"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="cityState col-12 d-flex justify-content-between">
                  <div className="cityForm text-start form-group col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="city">City </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="stateForm text-start form-group col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="provinceCode">Province Code </label>
                    <input
                      type="text"
                      id="provinceCode"
                      name="provinceCode"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="zipPhone text-start col-12 d-flex justify-content-between">
                  <div className="zipForm col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="zip">Zip </label>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="pincode"
                      id="zip"
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                  <div className="phoneForm col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="phone">Phone Number </label>
                    <PhoneInput
                      id="phone-input"
                      country={selectedCountry}
                      countryCodeEditable={false}
                      disableCountryGuess={true}
                      value={phoneNumber}
                      areaCodes={true}
                      onChange={handlePhoneNumberChange}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                    />
                  </div>
                </div>

                <div className="countryEmail col-12 d-flex justify-content-between">
                  <div className="country text-start form-group col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      maxLength={100}
                      name="email"
                      onChange={handleChange}
                      id="email"
                      required
                    />
                  </div>
                  <div className="country text-start form-group col-5 mx-3 my-3 d-flex flex-column">
                    <label htmlFor="country">Country code</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="country"
                      defaultValue="US"
                      disabled
                      id="country"
                    />
                  </div>
                </div>

                <div className="backSubmitButtonContainer d-flex justify-content-between mt-3 ">
                  <button
                    type="button"
                    className="back-btn-redeem-gift btn col-xl-4 col-lg-4 col-md-5 col-sm-5 "
                    onClick={() => navigateProductList()}
                  >
                    <i className="fa-sharp fa-solid fa-left-long"></i>
                    &nbsp;&nbsp; Back
                  </button>
                  <button
                    type="submit"
                    className="submit-btn-redeem-gift btn col-xl-4 col-lg-4 col-md-5 col-sm-5 "
                  >
                    Confirm <span className="small-screen-hide">Details</span>
                  </button>
                </div>
              </form>
              {open && (
                <Modal
                  open={open}
                  onClose={onCloseModal}
                  center
                  classNames={{
                    modal: "customModal",
                  }}
                  animationDuration={400}
                >
                  <div className="successModalContainer text-center">
                    <div className="confettiSvg text-center">
                      <img src={partyPopperSvg} alt="" srcSet="" />
                    </div>
                    <div className="hurrayHeading">Hurray!</div>
                    <div className="successContent">
                      Thank you for choosing this awesome gift
                      <br /> Your Gift of choice is on it’s way
                    </div>
                    <div className="mt-3">
                      <textarea
                        rows={3}
                        cols={35}
                        type="text"
                        placeholder="Add a thank you note (optional)"
                        onChange={(e) =>
                          setFeedback({ ...feedback, message: e.target.value })
                        }
                        style={{
                          width: "90%",
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          resize: "vertical",
                        }}
                      />
                    </div>
                    <button
                      onClick={onCloseModalSubmit}
                      className="btn done-btn"
                    >
                      Done
                    </button>
                  </div>
                </Modal>
              )}

              {showSizeChart && (
                <Modal
                  open={showSizeChart}
                  onClose={onCloseModal2}
                  center
                  classNames={{
                    modal: "customModal2",
                  }}
                  animationDuration={400}
                >
                  <div className="successModalContainer text-center">
                    <div className=" text-center">
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "70vh",
                          objectFit: "contain",
                        }}
                        src={sizeCharImage}
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

<script src="js/bootstrap.bundle.min.js"></script>;
