import React, { useEffect, useState } from "react";
import congrats from "../assets/images/congrats.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { getTemplateData } from "../services/apiservice";
import toplogo from "../assets/images/tlogo.png";
import "../styles/feedback.css";
import Footer from "./Footer";
import { useLocation } from "react-router";

const emailList = ["faraz@swageazy.com"];

export const FeedbackPage = () => {
  const [feedback, setFeedback] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const [coupId, setCoupId] = useState();
  const [loading, setLoading] = useState(false);
  const [redemptionData, setRedemptionData] = useState({
    backroundColor: "",
    logoImageUrl: "",
    buttonColor: "",
    logoImage: "",
  });
  const { state } = useLocation();
  const shipment_id = state.shipmentId;
  const inventory_id = state.inventoryId;
  const userEmail = state.email;
  const isEmailFromList = userEmail ? emailList.includes(userEmail) : false;

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      window.location.href = "https://swageazy.com/";
      return;
    }
    getTemplateIdFromCoupon();
  }, []);

  function getTemplateIdFromCoupon() {
    getTemplateData()
      .then((response) => {
        const data = response.data.result;
        setRedemptionData({
          backgroundColor:
            data.pageBackgroundColor || data.page_background_color,
          logoImageUrl: data.logoImage,
          buttonColor: data.buttonColor,
          logoImage: data.logoImage,
        });
        setCoupId(data.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  function handleSubmitFeedback() {
    if (!feedback || !coupId) {
      toast.error("Coupon ID or Feedback cannot be null or empty", {
        position: "top-center",
      });
      return;
    }
    if (feedback.length === 0) {
      setTimeout(() => {
        toast.warning("Feedback field cannot be empty", {
          position: "bottom-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }, 100);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_URL}/v4/customer/redemption/feedback?token=${token}`,
        {
          shipment_id,
          feedback_message: feedback,
          inventory_id,
        }
      )
      .then((response) => {
        if (response.data.success) {
          toast.success("Thanks for your valuable feedback!", {
            position: "bottom-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          window.location.href = "https://swageazy.com/";
        } else {
          toast.error("Failed to submit feedback.", {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.error("Failed to submit feedback.", {
          position: "top-center",
        });
      });
  }

  function handleSkipForNow() {
    window.location.href = "https://swageazy.com/";
  }

  return (
    <>
      <div className="">
        {loading ? (
          <div className="loading-indicator-admin"> </div>
        ) : (
          <div className="">
            <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="top-margin-two header px-3 py-3">
                <div className="">
                  <img
                    className="img-fluid theme-logo-image"
                    src={redemptionData.logoImage || toplogo}
                  />
                </div>
              </div>
            </div>
            <div className="py-4 mt-4 mx-auto order-confirm">
              <div
                className="row text-center rounded-top justify mt-2"
                style={{ background: "#5945D7" }}
              >
                <div className="col-12 mt-4">
                  <div
                    className="bg-card position-relative"
                    style={{ marginBottom: "6.6rem" }}
                  >
                    <img
                      src={congrats}
                      className="position-absolute top-0 left"
                    />
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ background: "#5945D7" }}>
                <div
                  className="col-12 px-5 text-center mb-4"
                  style={{ marginTop: "120px" }}
                >
                  <p
                    style={{
                      color: "#FCC97D",
                      fontSize: "25px",
                      fontWeight: "700",
                    }}
                  >
                    Order Confirmed!
                  </p>
                  <p className="text-white ">Your Order has been received.</p>
                </div>
              </div>

              <div className="row p-5 py-0 mb-5 rounded bg-white shadow">
                <p className="mt-3" style={{ fontWeight: 600 }}>
                  Write your feedback
                </p>
                <textarea
                  id="feedback"
                  name="feedback"
                  className="w-100 form-control"
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
                <div className="row p-3 pt-5 btn-div">
                  <div className="col-12 col-xl-6 col-md-6 col-sm-6">
                    <button
                      className="border-1 bg-white p-2 px-3 rounded-pill skip"
                      style={{ color: "#3F3D56" }}
                      onClick={handleSkipForNow}
                    >
                      Skip for now
                    </button>
                  </div>
                  <div className="col-12 col-xl-6 col-md-6 col-sm-6">
                    <button
                      className="border-0 p-2 px-4 rounded-pill text-white py-2 submit"
                      style={{ background: "#D7458F" }}
                      onClick={handleSubmitFeedback}
                    >
                      Submit Feedback
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
