import axios from "axios";
import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import toplogo from "../assets/images/tlogo.png";
import "../styles/checkout-page.css";
import { toast, ToastContainer } from "react-toastify";
import { getTemplateData } from "../services/apiservice";
import Footer from "./Footer";
import { Modal } from "react-responsive-modal";
import "../styles/Multi-pack-list.css";

export const CheckoutPage = () => {
  const [checkoutDetails, setCheckoutDetails] = useState({
    backroundColor: "",
    logoImageUrl: "",
    buttonColor: "",
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const [showProducts, setShowProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [swagpacks, setSwagpacks] = useState([]);
  const [showPackDetails, setShowPackDetails] = useState();
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState();
  const token = queryParameters.get("token");
  const navigate = useNavigate();
  let params = { token: token };
  const [selectedProductImage, setSelectedProductImage] = useState();
  const [currIndex, setCurrIndex] = useState(0);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      setLoading(false);
      navigate("/");
      return;
    }
    getCouponItems();
  }, []);

  function getTemplateIdFromCoupon() {
    getTemplateData()
      .then((e) => {
        setCheckoutDetails({
          backgroundColor: e.data.result.pageBackgroundColor,
          logoImageUrl: e.data.result.logoImage,
          buttonColor: e.data.result.buttonColor,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getTemplateIdFromCoupon();
  }, [swagpacks]);

  function getCouponItems() {
    axios
      .get(
        `${process.env.REACT_APP_URL}/v4/customer/redemption/couponItems?token=${token}`
      )
      .then((res) => {
        if (res.data.success && res.data.result) {
          if (
            res.data.result.swagpacks &&
            res.data.result.swagpacks.length > 0
          ) {
            setSwagpacks(res.data.result.swagpacks);
            setShowProducts(res.data.result.show_products);
          } else {
            setTimeout(() => {
              setLoading(false);
              toast.warning("Swagpacks not found", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }, 100);
            navigate({
              pathname: "/",
              search: `?${createSearchParams(params)}`,
            });
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          toast.warning(
            err.response.data.message ||
              "Something went wrong, please try again later",
            {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }, 100);
        navigate({
          pathname: "/",
          search: `?${createSearchParams(params)}`,
        });
      });
  }

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedProduct();
  };

  // handling route of form page, when selectedPack state will update then the route will called
  useEffect(() => {
    if (selectedPack) {
      navigate(`/redemption/details?token=${params.token}`, {
        state: { selectedPack },
      });
    }
  }, [selectedPack]);
  const handleRoute = () => {
    setSelectedPack({ selectedPack: swagpacks });
  };

  // this useeffect code will execute after selectedProduct get set
  useEffect(() => {
    if (selectedProduct) {
      openModal();
    }
  }, [selectedProduct]);

  const showPackProducts = (pack) => {
    if (showProducts === 1) {
      setShowPackDetails(pack);
      setSelectedProduct(pack.products[0]);
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setCurrIndex(0);
    scrollProductImagesCarousel(product?.images, 0);
  };

  const scrollProductsCarousel = (direction) => {
    const container = document.querySelector(".products-carousel");
    const scrollAmount = 333;

    if (direction === "left") {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  };

  const scrollProductImagesCarousel = (images, value) => {
    setCurrIndex((prevCurrIndex) => {
      let newIndex = prevCurrIndex + value;

      if (newIndex >= images.length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = images.length - 1;
      }

      setSelectedProductImage(images[newIndex]);

      return newIndex;
    });
  };

  return (
    <div className="m-0 p-0">
      {loading ? (
        <div className="loading-indicator-admin"> </div>
      ) : (
        <div className="row ">
          <ToastContainer />
          <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="top-margin-two header">
              <div className="col-sm-12 logodiv p-3">
                <img
                  className="img-fluid theme-logo-image"
                  src={checkoutDetails.logoImageUrl || toplogo}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row p-4">
            <div
              id="checkoutbtn"
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 redeem-btn-div"
            >
              <button
                onClick={() => handleRoute()}
                className="btn btn-primary shadow rounded redeem-btn"
                style={{
                  backgroundColor: "#d7458f",
                  border: "none",
                  color: "#fff",
                }}
              >
                Redeem now
              </button>
            </div>
          </div>
          <div className="row m-0">
            {swagpacks.map((pack, index) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 pb-4"
                key={index}
              >
                <div className="col text-center">
                  <div
                    className={`rounded product-card border-none border-0 shadow p-4 mt-2 pack-${
                      index + 1
                    }`}
                  >
                    <div
                      className="img-div"
                      style={{
                        backgroundColor: "#FFFFFF",
                        cursor: showProducts === 1 ? "pointer" : "default",
                      }}
                      onClick={() => showPackProducts(pack)}
                    >
                      <img
                        src={
                          pack.pack_image_url ||
                          "https://developmentprocureasy.s3.ap-south-1.amazonaws.com/swageazyproducts/Swageazy-Swagpack-Without-Background_1699332397642.png"
                        }
                        className="mockimg"
                        alt={`Product ${index}`}
                      />
                    </div>

                    <div className="card-body border-0 pt-3">
                      <label
                        className="card-title p-0 mt-1"
                        style={{ fontWeight: "bold" }}
                        title={pack.label}
                      >
                        {pack.label.split(" ").slice(0, 2).join(" ")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </div>
      )}

      <Modal
        open={open}
        onClose={closeModal}
        center
        classNames={{
          modal: "packProductsModal",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={400}
      >
        <div className="row">
          {showPackDetails?.description && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1">
              <span className="desc">Description:</span>{" "}
              <span>{showPackDetails?.description}</span>
            </div>
          )}
          {open === true && (
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="product-carousel-container p-0">
                  <div className="product-images-carousel carousel">
                    {selectedProduct?.images?.map((image, index) => (
                      <div
                        key={index}
                        className="product-thumbnail row"
                        style={{ cursor: "default" }}
                      >
                        <div
                          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                          key={index}
                        >
                          <div className={`product-carousel-cards mt-2`}>
                            <div
                              className="product-imgs-div"
                              style={{
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <img
                                src={
                                  selectedProductImage?.image_url ||
                                  "https://developmentprocureasy.s3.ap-south-1.amazonaws.com/swageazyproducts/Swageazy-Swagpack-Without-Background_1699332397642.png"
                                }
                                className="img-fluid image"
                                alt={`Product ${index}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {selectedProduct?.images &&
                  selectedProduct?.images?.length > 1 ? (
                    <button
                      className="product-carousel-prev"
                      onClick={() =>
                        scrollProductImagesCarousel(selectedProduct.images, -1)
                      }
                    >
                      {"<"}
                    </button>
                  ) : (
                    ""
                  )}
                  {selectedProduct?.images &&
                  selectedProduct?.images?.length > 1 ? (
                    <button
                      className="product-carousel-next"
                      onClick={() =>
                        scrollProductImagesCarousel(selectedProduct.images, 1)
                      }
                    >
                      {">"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div style={{ padding: "20px" }}>
                  <div className="name-desc">
                    <h4>{selectedProduct?.product_name || "--"}</h4>
                    <p>{selectedProduct?.desc || "--"}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                <div className="product-carousel-container">
                  <div className="products-carousel carousel">
                    {showPackDetails?.products?.map((product, index) => (
                      <div
                        key={index}
                        className="product-thumbnail row"
                        onClick={() => handleProductClick(product)}
                      >
                        <div
                          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                          key={index}
                        >
                          <div
                            className={`rounded product-carousel-cards border-none border-0 shadow p-4 mt-2 pack-${
                              index + 1
                            }`}
                          >
                            <div
                              className="img-div"
                              style={{
                                backgroundColor: "#FFFFFF",
                                cursor: showProducts ? "pointer" : "default",
                              }}
                            >
                              <img
                                src={
                                  product.image_url ||
                                  "https://developmentprocureasy.s3.ap-south-1.amazonaws.com/swageazyproducts/Swageazy-Swagpack-Without-Background_1699332397642.png"
                                }
                                className="mockimg"
                                alt={`Product ${index}`}
                              />
                            </div>

                            <div className="card-body border-0 pt-3">
                              <label
                                className="card-title p-0 mt-1"
                                style={{ fontWeight: "bold" }}
                                title={product.name}
                              >
                                {product.product_name
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {showPackDetails?.products &&
                  showPackDetails?.products?.length > 3 ? (
                    <button
                      className="product-carousel-prev"
                      onClick={() => scrollProductsCarousel("left")}
                    >
                      {"<"}
                    </button>
                  ) : (
                    ""
                  )}
                  {showPackDetails?.products &&
                  showPackDetails?.products?.length > 3 ? (
                    <button
                      className="product-carousel-next"
                      onClick={() => scrollProductsCarousel("right")}
                    >
                      {">"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
